*{margin: 0;padding: 0;}
@font-face{font-family: Roboto-Bold;src: url(Roboto-Bold.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: Roboto-Medium;src: url(Roboto-Medium.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: Roboto-Regular;src: url(Roboto-Regular.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: Roboto-Black;src: url(Roboto-Black.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: Roboto-Light;src: url(Roboto-Light.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: BlenderPro-Bold;src: url(BlenderPro-Bold.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: BlenderPro-Medium;src: url(Blender-Pro-Medium.otf);font-weight: normal;font-style: normal;}
@font-face{font-family: BlenderPro-Book;src: url(Blender-Pro-Book.otf);font-weight: normal;font-style: normal;}
@font-face{font-family: ProximaNova-Regular;src: url(ProximaNova-Regular.otf);font-weight: normal;font-style: normal;}

html{width: 100%;height: 100%;}
body {font-family: Roboto-Regular;width: 100%;height: 100%;margin: 0 auto;

}
a {text-decoration: none;border: none;color: #000;font-family: Roboto-Regular;}
img {text-decoration: none;border: none;}
ul {list-style: none;}
li {list-style-type: none;}
input{outline: none;font-family: Roboto-Regular;}
button{outline: none;font-family: Roboto-Regular;}
select{outline: none;font-family: Roboto-Regular;}
textarea{outline: none;font-family: Roboto-Regular;}

::-webkit-input-placeholder { /* Chrome */ color: rgb(255,255,255); }
:-ms-input-placeholder { /* IE 10+ */ color: rgb(255,255,255); }
::-moz-placeholder { /* Firefox 19+ */ color:  rgb(255,255,255); }
:-moz-placeholder { /* Firefox 4 - 18 */ color:  rgb(255,255,255); }

#root{height: 100%;}
.App{height:100%}


@media screen and (min-width: 768px) {
.container-home{max-width:767px;margin:0px auto;padding:0px;background-color: #ffffff;
    overflow: auto;height: 100%;}
.login-header{    
    z-index: 999;
    position: sticky;
    background-color: #000000;
    padding: 13px 105px 13px 105px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;}
.logo-l-header{width:176px;height:37px;cursor: pointer;}

.right-header{overflow: hidden;float: right;}
.about{color:#ffffff;font-size: 15px;float: left;margin-top: 10px;padding-right: 20px;border-right: 1px solid #ffffff;}
.contact{color:#ffffff;font-size: 15px;float:left;margin-top: 10px;margin-left: 20px;}

.background-login{
    background: url(../images/background1.jpg) no-repeat 0 0;
    background-size: 100% 100%;
    position: relative;
    height: 785px;}
    .right-header img{display: none;width:29px;height:20px;float: right;}

/* __________________HOME SCREEN ___________*/

.container-landing{margin: 0px auto;
    padding: 0px;
    background-color: #000000;
    height: 100%;}


.authenticate{padding:96px 0px 0px 109px;overflow: hidden;top: 67px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;}
.auth-pp{color:#ffffff;font-size: 28px;font-family:BlenderPro-Medium ;margin-bottom: 19px;}

.auth-box{padding:25px 20px 24px 30px;border:1px solid rgba(255, 255, 255, 0.32);border-radius: 7px;
width:426px;overflow: hidden;margin-bottom: 29px; background-color: rgba(0,0,0,0.56);}
.auth-l{float: left;}

.yellow {}
/* .white-t{color:#ffffff !important} */

.auth-no{color:#ffffff;font-size: 24px;font-family:BlenderPro-Medium;float: left;margin-right: 9px;}
.auth-p{color:#ffffff;font-size: 24px;font-family:BlenderPro-Medium;float: left;width:315px}

.auth-r{float: right;}
.auth-r img{width:24px;height:11px;margin-top: 23px; display: none;}

.a-extra{clear: both;
    font-family: ProximaNova-Regular;
    font-size: 16px;
    color: #ffffff;
    margin-top: 31px;
    margin-left: 41px;}
.auth-box:hover .auth-r img{display: block !important;}
.auth-box:hover{
    background-color: rgba(238, 207, 98, 0.9);
    color:#000000;
}

.auth-box:hover span {
    color:#000000;
}

.auth-box:hover p {
    color:#000000;
}


/* ________BOTTOM ___________________*/

.tips{bottom: 0;
    top: -170px;
    position: relative;
    left: 0;
    border-bottom: 1px solid #666666;
    right: 0;}
.tips-p-a{font-size:28px;color:#ffffff;text-align: center;font-family:BlenderPro-Book;}
.tips-p-b{font-size: 35px;color:#ffffff;text-align: center;font-family:BlenderPro-Medium;margin-bottom: 38px;}

.tips-bottom{overflow: hidden;padding: 0px 275px;
    margin: 0px auto; background-color: #000000;}
.tips-box:first-child{padding-right:80px;}
.tips-box:last-child{padding-left:80px}
.second-box{padding:0px 80px 0px 80px !important; border-left:1px solid #646464;border-right:1px solid #646464}
.tips-box{overflow: hidden;float: left; padding-bottom: 88px;}
.t-i{width:53px;height: 53px;margin: 0px auto;margin-bottom: 14px;}
.t-i img{width:100%;height: 100%;}
.tips-box p{font-size: 14px;line-height: 17px;color:#ffffff;width:157px;
    font-family:ProximaNova-Regular; text-align: center;}
.b-extra{width: 805px; margin: 0px auto;}

.video-b{    width: 100%;
    height: 444px;margin-top: -171px;}
.video-b img{width:100%;height: 100%;}
.p-img{    width: 106px !important;
    height: 106px !important;
    position: relative;
    top: -281px;
    bottom: 0;
    left: 622px;
    right: 0;}

#video{height: 444px;
    width: 100%;}

.grey-div{background-color: #e7e7e7;overflow: hidden;padding:63px 0px 76px 112px;}
.grey-l{float: left;margin-right: 150px;}
.retail{font-size: 16px;color:#333333;font-family: ProximaNova-Regular;margin-bottom: 9px;}
.grey-l-div{padding:9px 0px 11px 18px;background-color:#D7D7D7;overflow: hidden;margin-bottom: 23px;}
.arrow{overflow: hidden;float: left;}
.arrow:first-child{margin-right: 57px;}
.arrow p{font-size: 16px;color:#333333;font-family: ProximaNova-Regular ;margin-right: 7px;float: left;}
.arrow img{width:14px;height:8px}

.scroll-div{margin-bottom: 58px;height: 174px;
    overflow: auto;}
.our-retail{font-size: 16px;color:#333333;font-family: ProximaNova-Regular;margin-bottom: 16px;}
.float-it{overflow: hidden;margin-bottom: 17px;}
.l-i{float: left;width:206px;margin-right: 35px;}
.black-i{color:#333333;font-size: 14px;font-family: ProximaNova-Regular;margin-bottom: 2px;}
.white-i{color:#666666;;font-size: 13px;font-family: ProximaNova-Regular}
.r-i{float: left;width:206px}

.call-div{border: 2px solid #333333;padding:17px 12px 16px 15px;
    overflow: hidden;float: left; border-radius: 10px;}
.call-div:first-child{margin-right: 18px;}
.call-div img{width:33px;height: 33px;margin-right: 11px;float: left;}
.call-r{overflow: hidden;float: left;}
.call-r-a{color:#333333;font-size: 12px;font-family: ProximaNova-Regular;}
.call-r-b{color:#333333;font-size: 25px;font-family: BlenderPro-Medium;margin-top: -7px;}


.grey-r{float: left; width: 30%;}
.authh{font-size: 16px;color:#333333;font-family: ProximaNova-Regular;margin-bottom: 18px;}

.authh-d{overflow: hidden;}
.authh-l{float: left;margin-right: 60px; width: 40%;     margin-bottom: 10px;}
.authh-a{color:#333333;font-size: 16px;font-family: ProximaNova-Regular;margin-bottom: 8px;}
.authh-b{
    color:#666666;
    font-size: 13px;
    font-family: ProximaNova-Regular;
    /* text-decoration: underline #666666; */
}

.authh-r{float: left; width: 40%; margin-bottom: 10px;}

.scroll-div::-webkit-scrollbar {
    width: 5px;
      
}
.scroll-div::-webkit-scrollbar-thumb {
    -webkit-border-radius: 100px;
    border-radius: 100px;
    background: #B4B4BC;
    -webkit-box-shadow: inset 0 0 0px  #2A5EBD;
   
}


.concl{background-color: #000000;padding:64px 0px 67px 0px;}
.concl-a{color:#ffffff;font-size: 28px;font-family: BlenderPro-Book;margin-bottom: 21px;text-align: center;}
.concl-b{color:#ffffff;font-size: 14px;font-family: ProximaNova-Regular;text-align: center;width:810px;
margin: 0px auto;}

.social{color: #ffffff;
    font-size: 28px;
    font-family: BlenderPro-Book;
    margin-bottom: 21px;
    text-align: center;
    margin-top: 54px;
    margin-bottom: 28px;}

.socialss{overflow: hidden;width:242px;margin: 0px auto;}
.social-handle{float: left;margin-right: 24px;}
.social-handle img{width:42px;height:42px}
.last-social{margin-right: 0px !important;}

.bottom-web{background-color:#1E1E1E;padding:16px 0px 23px 0px;text-align: center; }
.bottom-web span{color:#ffffff;font-size: 12px;font-family: ProximaNova-Regular;text-align: center;}

.bottom-wap{display: none;}

.bottom-wap p{color:#ffffff;font-size: 12px;font-family: ProximaNova-Regular;text-align: center;}


.s-select{
    font-size: 16px;
    font-family: ProximaNova-Regular;
    background-color: transparent;
    border: none;
    color: #333333;

}

.cross-i{position: absolute;
top: 97px;
right: 51px;
z-index:99;
}

}










/*----------------------------------MEDIA QUERY------------------------------------*/
@media only screen and (max-width: 767px) {


    .container-home{max-width:767px;margin:0px auto;padding:0px;background-color: #ffffff;
        overflow: auto;height: 100%;}
    .login-header{    
        z-index: 999;
        position: fixed;
        background-color: #000000;
        padding: 18px 16px 14px 13px;
        top: 0;
        /* bottom: 0;
        left: 0;
        right: 0; */
        width:92%;
    }

    .logo-l-header{width:129px;height:27px;cursor: pointer;}
    
    .right-header{overflow: hidden;float: right;}
    .about{display:none;color:#ffffff;font-size: 15px;float: left;margin-top: 10px;padding-right: 20px;border-right: 1px solid #ffffff;}
    .contact{display:none;color:#ffffff;font-size: 15px;float:left;margin-top: 10px;margin-left: 20px;}
    
    .background-login{
        background: url(../images/background2.jpg) no-repeat 0 0;
        background-size: 100%;
        position: relative;
        height: 435px;}
    
    .right-header img{display: block;width:29px;height:20px;float: right;margin-top: 3px;}
    
    
    /* __________________HOME SCREEN ___________*/
    
    .container-landing{margin: 0px auto;
        padding: 0px;
        max-width: 767px;
        background-color: #000000;
        padding-top: 64px;
       
    overflow: hidden;}
    
    
    .authenticate{padding:237px 16px 0px 16px;overflow: hidden;top: 11%;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;}
    .auth-pp{color:#ffffff;font-size: 24px;font-family:BlenderPro-Medium ;margin-bottom: 14px;}
    
    .auth-box{padding:16px 2px 16px 20px;border:1px solid rgba(255, 255, 255, 0.32);border-radius: 7px;
   overflow: hidden;margin-bottom: 12px; background-color: rgba(0,0,0,0.56);}
    .auth-l{}
    
    
    
    .auth-no{color:#ffffff;font-size: 18px;font-family:BlenderPro-Medium;float: left;margin-right:5%;}
    .auth-p{color:#ffffff;font-size: 18px;font-family:BlenderPro-Medium;float: left;width:84%}
    
    .auth-r{float: right;}
    .auth-r img{width:24px;height:11px;margin-top: 23px; display: none;}
    
    .a-extra{clear: both;
        font-family: ProximaNova-Regular;
        font-size: 16px;
        color: #ffffff;
        margin-top: 31px;
        margin-left: 36px;}

        
    /* .auth-box:hover .auth-r img{display: none !important;}
    .auth-box:hover{
        background-color: rgba(238, 207, 98, 0.9);
        color:#000000;
    }
    
    .auth-box:hover span {
        color:#000000;
    }
    
    .auth-box:hover p {
        color:#000000;
    } */
    
    
    /* ________BOTTOM ___________________*/
    
    .tips{bottom: 0;
        top: 168px;
        position: relative;
        left: 0;
        border-bottom: 1px solid #666666;
        right: 0;}
    .tips-p-a{font-size:25px;color:#ffffff;text-align: center;font-family:BlenderPro-Book;}
    .tips-p-b{font-size: 28px;color:#ffffff;text-align: center;font-family:BlenderPro-Medium;margin-bottom: 38px;}
    
    .tips-bottom{overflow: hidden;
        margin: 0px auto; background-color: #000000;}

        /* padding: 0px 275px; */

    .tips-box:first-child{}
    .tips-box:last-child{}
    .second-box{}
    .tips-box{overflow: hidden; width: 238px;
        margin: 0px auto;
        text-align: center;margin-bottom: 48px;}
    .t-i{width:53px;height: 53px;margin: 0px auto;margin-bottom: 14px;}
    .t-i img{width:100%;height: 100%;}
    .tips-box p{font-size: 14px;line-height: 17px;color:#ffffff;
        font-family:ProximaNova-Regular; text-align: center;}
    .b-extra{ margin: 0px auto;}
    
    .video-b{    width: 100%;
        height: 196px;margin-top: 168px;}
    .video-b img{width:100%;height: 100%;}
    .p-img{    width: 54px !important;
        height: 54px !important;
        position: relative;
        top: -126px;
        bottom: 0;
        left: 43%;
        right: 0;}
    
    #video{height: 196px;
        width: 100%;}
    
    .grey-div{background-color: #e7e7e7;overflow: hidden;padding:24px 15px 76px 15px;}
    .grey-l{float: left;}
    .retail{font-size: 16px;color:#333333;font-family: ProximaNova-Regular;margin-bottom: 9px;}
    .grey-l-div{padding:9px 0px 11px 18px;background-color:#D7D7D7;overflow: hidden;margin-bottom: 23px;}
    .arrow{overflow: hidden;float: left;}
    .arrow:first-child{/*margin-right: 57px;*/ margin-right: 6%;}
    .arrow p{font-size: 16px;color:#333333;font-family: ProximaNova-Regular ;margin-right: 7px;float: left;}
    .arrow img{width:14px;height:8px}
    
    .scroll-div{margin-bottom: 58px;height: 174px;
        overflow: auto;}
    .our-retail{font-size: 16px;color:#333333;font-family: ProximaNova-Regular;margin-bottom: 16px;}
    .float-it{overflow: hidden;}
    .l-i{float: left;margin-bottom: 18px;}
    .black-i{color:#333333;font-size: 14px;font-family: ProximaNova-Regular;margin-bottom: 3px;}
    .white-i{color:#666666;;font-size: 13px;font-family: ProximaNova-Regular}
    .r-i{float: left;margin-bottom: 18px;}
    
    .call-div{border: 2px solid #333333;    padding: 11px 12px 11px 12px;
        overflow: hidden;float: left; border-radius: 10px;width: 91%;
        margin: 0px auto;}
    .call-div:first-child{margin-bottom: 12px;}
    .call-div img{width:33px;height: 33px;margin-right: 11px;float: left;}
    .call-r{overflow: hidden;word-break: break-word;}
    .call-r-a{color:#333333;font-size: 12px;font-family: ProximaNova-Regular;margin-bottom: 3px;}
    .call-r-b{color:#333333;font-size: 19px;font-family: BlenderPro-Medium;margin-top: -7px;}
    
    
    .grey-r{float: left; 
        /* width: 30%; */
    }
    .authh{font-size: 16px;color:#333333;font-family: ProximaNova-Regular;margin-bottom: 18px;}
    
    .authh-d{overflow: hidden;}
    .authh-l{float: left;margin-right: 58px; width: 40%;     margin-bottom: 10px;}
    .authh-a{color:#333333;font-size: 16px;font-family: ProximaNova-Regular;margin-bottom: 8px;}
    .authh-b{color:#666666;font-size: 13px;font-family: ProximaNova-Regular;text-decoration: underline #666666;}
    
    .authh-r{float: left; width: 40%; margin-bottom: 10px;}
    
    .scroll-div::-webkit-scrollbar {
        width: 5px;
          
    }
    .scroll-div::-webkit-scrollbar-thumb {
        -webkit-border-radius: 100px;
        border-radius: 100px;
        background: #B4B4BC;
        -webkit-box-shadow: inset 0 0 0px  #2A5EBD;
       
    }
    
    
    .concl{background-color: #000000;padding:25px 10px 32px 10px;}
    .concl-a{color:#ffffff;font-size: 25px;font-family: BlenderPro-Book;margin-bottom: 18px;text-align: center;}
    .concl-b{color:#ffffff;font-size: 14px;font-family: ProximaNova-Regular;text-align: center;
    margin: 0px auto;}
    
    
    .bottom-wap{background-color:#1E1E1E;padding:16px 0px 23px 0px }
    .bottom-wap p{color:#ffffff;font-size: 12px;font-family: ProximaNova-Regular;text-align: center;}
    
    .s-select{
        font-size: 16px;
        font-family: ProximaNova-Regular;
        background-color: transparent;
        border: none;
        color: #333333;
        -webkit-appearance: button;
    
    }

    .slide-it-down{top:273px}

    .slide-it-so{margin-top: 273px;}

    .slide-it-down-auth{top:204px}

    .slide-it-so-auth{margin-top: 204px;}

    .slide-it-down-otp{top:125px}

    .slide-it-so-otp{margin-top: 125px;}


    /*------------------------------------------------*/

    .select-option{
        width: 100%;
	    overflow: hidden;
        background: url("../images/down.svg") no-repeat 100% 50%;
        background-size: 8.33px 5px;	   
    }

    .select-option select{    width: 100%;
        border: none;
        box-shadow: none;
        background: transparent;
        background-image: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        font-size: 16px;
        padding-right: 10px;
        text-align: left;
        color: #262628;
        cursor: pointer;
        outline: none;}

    .select-option-city{
        width: 100%;
        overflow: hidden;
        background: url("../images/down.svg") no-repeat 100% 50%;
        background-size: 8.33px 5px;	   
    }

    .select-option-city select{    width: 100%;
        border: none;
        box-shadow: none;
        background: transparent;
        background-image: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        padding-right: 15px;
        font-size: 16px;
        text-align: left;
        color: #262628;
        cursor: pointer;
        outline: none;}    


        .bottom-wap{display: block;}
      .bottom-web{display: none;}
        .social{display: none;}
        .cross-i{display: none;}
        .socialss{display: none;}
}

@media only screen and (max-width: 400px) {
    .background-login{
        height: 410px;
    }
}

@media only screen and (max-width: 360px) {
    .background-login{
        height: 375px;
    }
}