*
{
  font-family: 'PT Sans Caption', sans-serif, 'arial', 'Times New Roman';
}
    .error .clip .shadow {
        height: 180px;
    }
    .error .clip:nth-of-type(2) .shadow {
        width: 130px; 
    }
    .error .clip:nth-of-type(1) .shadow, .error .clip:nth-of-type(3) .shadow {
        width: 250px;
    }
    .error .digit {
        width: 150px;  
        height: 150px; 
        line-height: 150px; 
        font-size: 120px;
        font-weight: bold;
    }
    .error h2  {
        font-size: 32px;
    }
    .error .errorPage {
        margin-top: 10%;
        position: relative;
        height: 250px;
        padding-top: 40px;
    }
    .error .errorPage .clip {
        display: inline-block;
        transform: skew(-45deg);
    }
    .error .clip .shadow {
        overflow: hidden;
    }
    .error .clip:nth-of-type(2) .shadow {
        overflow: hidden;
        position: relative;
        box-shadow: inset 20px 0px 20px -15px rgba(150, 150, 150,0.8), 20px 0px 20px -15px rgba(150, 150, 150,0.8);
    }
    
    .error .clip:nth-of-type(3) .shadow:after, .error .clip:nth-of-type(1) .shadow:after {
        content: "";
        position: absolute;
        right: -8px;
        bottom: 0px;
        z-index: 9999;
        height: 100%;
        width: 10px;
        background: linear-gradient(90deg, transparent, rgba(173,173,173, 0.8), transparent);
        border-radius: 50%;
    }
    .error .clip:nth-of-type(3) .shadow:after  {
        left: -8px;
    }
    .error .digit {
        position: relative;
        top: 8%;
        color: white;
        background: #ff3f3f;
        border-radius: 50%;
        display: inline-block;
        transform: skew(45deg);
    }
    .error .clip:nth-of-type(2) .digit {
        left: -10%;
    }
    .error .clip:nth-of-type(1) .digit {
        right: -20%;
    }
    .error .clip:nth-of-type(3) .digit {
        left: -20%;
    }    
    .error h2 {
        color: #A2A2A2;
        font-weight: bold;
        padding-bottom: 20px;
    }


@media(max-width: 767px) {
.error .clip .shadow {
                height: 100px;  
            }
.error .clip:nth-of-type(2) .shadow {
                width: 80px; 
            }
.error .clip:nth-of-type(1) .shadow, .error .clip:nth-of-type(3) .shadow   {
                width: 100px; 
            }
 .error .digit {
                width: 80px;   
                height: 80px;  
                line-height: 80px;
                font-size: 52px;
            }
  .error h2  {
                font-size: 24px;
            }
       
  .error .errorPage
  {
    height: 150px;
  }
}

.errorContainer{

  overflow: hidden;
    max-width: 767px;
    margin: 0 auto;
    padding: 0px !important;
    height: 100%;
}
.text-center-error{

    text-align: center;
    width: 100%;
}
