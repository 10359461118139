*{margin: 0;padding: 0;}
@font-face{font-family: BlenderPro-Bold;src: url(BlenderPro-Bold.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: BlenderPro-Medium;src: url(Blender-Pro-Medium.otf);font-weight: normal;font-style: normal;}
@font-face{font-family: BlenderPro-Book;src: url(Blender-Pro-Book.otf);font-weight: normal;font-style: normal;}
@font-face{font-family: Roboto-Regular;src: url(Roboto-Regular.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: ProximaNova-Regular;src: url(ProximaNova-Regular.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: Proxima-Nova-Bold;src: url(Proxima-Nova-Bold.otf);font-weight: normal;font-style: normal;}
@font-face{font-family: Roboto-Medium;src: url(Roboto-Medium.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: Roboto-Bold;src: url(Roboto-Bold.ttf);font-weight: normal;font-style: normal;}

html{width: 100%;height: 100%; scroll-behavior: smooth;}
body {font-family: BlenderPro-Bold;width: 100%;height: 100%;margin: 0 auto;

}
a {text-decoration: none;border: none;color: #000;font-family: BlenderPro-Bold;}
img {text-decoration: none;border: none;}
ul {list-style: none;}
li {list-style-type: none;}
input{outline: none;font-family: BlenderPro-Bold;}
button{outline: none;font-family: BlenderPro-Bold;}
select{outline: none;font-family: BlenderPro-Bold;}
textarea{outline: none;font-family: BlenderPro-Bold;}

::-webkit-input-placeholder { /* Chrome */ color: rgb(255,255,255); }
:-ms-input-placeholder { /* IE 10+ */ color:rgb(255,255,255); }
::-moz-placeholder { /* Firefox 19+ */ color: rgb(255,255,255); }
:-moz-placeholder { /* Firefox 4 - 18 */ color: rgb(255,255,255); }

#root{height: 100%;}
.App{height:100%}

@media screen and (min-width: 768px) {
    .blur-bk{
        display: none !important;
    }

.lg-visible{
    display:block;
}
.sm-visible{
    display: none;
}
.container-menu{float: left;
    width: 231px;
    position: sticky;
    top: 0;left: 0;
    margin: 0px auto;
    padding: 0px;
    background-color: #000000;
    height: 100%;}
.container-main{margin:0px auto;padding:0px;
    background-color:#000000;overflow: auto;height: 100%;} 
.container-signup{    margin: 0px auto;
    padding: 0px;
    background-color: #000000;}       
/* .container-logout{margin:0px auto;padding:0px; position: sticky; z-index: 999; 
    top: 0; left: 0; right: 0; bottom: 0;
    background-color:#000000;height: 64px;border-bottom: 1px solid #252525;}     */
.container-logout{    float: right;
    width: calc(100% - 232px);
    margin: 0px auto;
    padding: 0px;
    position: sticky;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000000;
    height: 64px;
    border-bottom: 1px solid #252525;}    
.header-menu{ height: 64px; overflow: hidden;}   
.pad-extra{padding: 49px 30px 0px 30px !important;}
/* .h-left{float: left; border-right: 1px solid #252525;
    padding: 14px 40px 14px 24px;} */
.h-left{
    position: relative;
    border-bottom: 1px solid #252525;
    border-right: 1px solid #252525;
    padding-bottom: 10px;
    float: left;
    padding-top: 14px;
    padding-left: 24px;
    padding-right: 39px;
    overflow: hidden;
    right: 28px;
}    
.h-left-m{display: none;}
.h-left-m img{display: none;}
.h-left img{width:167px; height:36px;}
.h-ryt{ margin-top: 3px;    
    padding: 16px 30px 16px 0px;
    float: right;} 
.logout-btn{    font-family: BlenderPro-Bold;
    font-size: 16px;
    border: none;
    background-color: #eecf62;
    cursor: pointer;
    color: #000000;
    padding: 8px 15.5px 8px 15.5px;}
.side-menu{
    padding: 0px 13px 0px 29px;
    border-right: 1px solid #252525;
    width: 189px;
    height: 100%;
}    
.menu{color: #7a7e87;
font-family: Roboto-Regular;
font-size: 12px;
margin-bottom: 9px;
padding-top: 41px;
clear: both;
}
.menu-div{ overflow: hidden;}
.sub-m-div{overflow: hidden; margin-top: 29.4px;}
.m-logo{width: 20px; height: 20px; float: left;}
.m-data{font-size: 16px; font-family: BlenderPro-Medium; color: #ffffff; float: left; margin-left: 12px;}
.m-active p{    margin-left: 11px !important;
    color: #eecf62 !important;
    font-family: BlenderPro-Bold !important;
    margin-top: 8px !important;}
.m-active svg:not(.star) path{stroke:#eecf62 !important;}    
.m-active svg:not(.star) rect{stroke:#eecf62 !important;}
.m-active svg.star path:nth-child(2){fill:#eecf62 !important;}    

.menu-auth{width: calc(100% - 292px);
    border-left: 1px solid #252525;
    float: right;
    padding: 29px 30px 0px 30px;
    height: 100%;
}
.menu-rdetails{
    width: calc(100% - 292px);
    border-left: 1px solid #252525;
    float: right;
    padding: 45px 30px 0px 30px;
    /* height: 100%; */
}
.menu-redeem{width: calc(100% - 292px);
    border-left: 1px solid #252525;
    float: right;
    padding: 57px 30px 50px 30px;
}
.m-heading{font-family: BlenderPro-Bold; font-size: 24px; color: #ffffff; float: left;}
.coins{float: right;
    position: relative;
    top: -10px;}
.coins img{    float: left;
    width: 22px;
    height: 22px;}
.coins p{margin-left: 16px;
    margin-top: -2px;
    font-size: 22px;
    font-family: BlenderPro-Book;
    float: right;
    color: #ffffff;
}
.coins p span{font-size: 22px;
    font-family: BlenderPro-Book;}
.p-code{font-family: BlenderPro-Book; font-size: 16px; color: #ffffff;}
.submit-btn{
    left: -374px;
    width: 145px;
    height: 36px;
    position: relative;
    margin-top: 16px;
    background-color: #eecf62;
    color: #000000;
    font-size: 18px;
    cursor: pointer;
    padding-top: 5px;
    font-family: BlenderPro-Bold;
    border: 1px solid #eecf62;
    top: 30px;
    float: left;
    right: 0;
    bottom: 0;}




.input-code{    
    color: #ffffff;
    font-family: BlenderPro-Book;
    font-size: 14px;
    /* float: left; */
    width: 350px;
    margin-top: 16px;
    padding: 9px 12px 9px 12px;
    border: 1px solid #ffffff;
    background-color: transparent;}

    .select-code {
        color: #ffffff;
        font-family: BlenderPro-Book;
        font-size: 14px;
        /* float: left; */
        width: 374px;
        margin-top: 16px;
        padding: 7px 12px 7px 0px;
        border: 1px solid #ffffff;
        background-color: transparent;
    }
.back-img{width: 24px; height: 24px;cursor: pointer;}    
.auth-form{margin-top: 34px;}
.rdetails-form{background-color: rgba(255,255,255,0.1); padding: 34px 42px 49px 42px; 
    overflow: hidden; margin-bottom: 54px;border-radius: 4px;}
.redeem-form{margin-top: 35px;}
.profile-form{margin-top: 42px;}
.f-form{background-color: rgba(255,255,255,0.1); position: relative;
    border-radius: 4px;padding: 40px 0px 0px 42px; height: 294px;}
.r-form{background-color: rgba(255,255,255,0.1); position: relative;
        border-radius: 4px;padding: 39px 0px 39px 41px;}    
.w-goods-div{background-color: rgba(255,255,255,0.1);
     overflow: hidden;
        border-radius: 4px;
        padding: 27.5px 37px 0px 33px;
    }             
.div-sign-up{    top: 67px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: calc(100% - 15px);} 
.signUp-form{margin: 67px 0px 0px 107px;
    background-color: rgba(0, 0, 0, 0.56);
    border-radius: 6px;
    padding: 26px 0px 30px 0px;
    border: 1px solid rgba(255, 255, 255, 0.15);
    width: 488px;}     
.s-text{font-family: BlenderPro-Medium; font-size: 28.4px; color: #ffffff; text-align: center;}      
.signUp-form input{border: 1px solid #ffffff;
    padding: 8px 0px 8px 12px;
    font-size: 14.1px;
    font-family: BlenderPro-Book;
    margin-top: 20px;
    color: #ffffff;
    background-color: transparent;
    margin-left: 43px;
    width: calc(100% - 104px);}
.div-checkbox{    margin-top: 17.4px;
    margin-left: 43px;}    
.checkbox-img{float: left;
    width: 17px;
    height: 17px;}
.tnc{margin-left: 27px;
    font-family: BlenderPro-Book;
    font-size: 14px;
    color: #ffffff;}
.tnc a{
    font-family: BlenderPro-Book;
    font-size: 14px;
    color: #ffffff;text-decoration: underline;}    
.s-btn{    margin-bottom: 20.5px;
    margin-top: 40px;
    width: 120px;
    background-color: #eecf62;
    color: #000000;
    font-family: BlenderPro-Bold;
    font-size: 19px;
    height: 36px;
    margin-left: 43px;
    border: 1px solid #eecf62;
    padding-top: 4px;
    cursor: pointer;
}
.acc-text{margin-left:43px;font-size: 16px; font-family: ProximaNova-Regular; color: #ffffff;}
.acc-text span{font-family: Proxima-Nova-Bold !important; text-decoration: underline; cursor: pointer;}
.login-form{margin: 67px 0px 0px 107px;
    background-color: rgba(0, 0, 0, 0.56);
    border-radius: 6px;
    padding: 42px 0px 41.5px 0px;
    border: 1px solid rgba(255, 255, 255, 0.15);
    width: 488px;}
.p-code-form{
    background-color: transparent !important;
    border: none !important;
    margin: 105px 0px 0px 200px !important;
}    
.login-form input, 
.login-form .custom-select {border: 1px solid #ffffff;
    padding: 9px 0px 8px 12px;
    font-size: 14.1px;
    font-family: BlenderPro-Book;
    margin-top: 20px;
    color: #ffffff;
    background-color: transparent;
    margin-left: 43px;
    width: calc(100% - 104px);}    
.enter-num{    margin-top: 22px;font-family: ProximaNova-Regular; font-size: 16px; color: #ffffff; margin-left: 43px;}    
.r-btn{    margin-bottom: 28.5px;
    margin-top: 21.4px;
    width: 180px;
    background-color: #eecf62;
    color: #000000;
    font-family: BlenderPro-Bold;
    font-size: 19px;
    height: 36px;
    margin-left: 43px;
    border: 1px solid #eecf62;
    padding-top: 4px;
    cursor: pointer;
}
.mar-tp-16{margin-top: 15.6px !important;}
.mar-tp-0{margin-top: 0 !important;}
.select_options__option  {font-size: 12.5px !important;}
.formLayout {
    height: auto !important;
    padding: 40px 0px 40px 42px;
}
.formLayout .b-ee-btn button.submit-btn {
    float: none;
    left: 0;
    top: 0;
    margin-left: -55px;
    padding-top: 0;
    margin-top: 0px;
}
.f-lft{text-align: left; margin-left: 43px;}
.enter-code{ margin-top: 6px;font-family: ProximaNova-Regular; font-size: 16px; color: #ffffff; margin-left: 43px;}    
.wid-code{width: 288px;}
.v-btn{    margin-bottom: 28.5px;
    margin-top: 21.4px;
    width: 120px;
    background-color: #eecf62;
    color: #000000;
    font-family: BlenderPro-Bold;
    font-size: 19px;
    height: 36px;
    margin-left: 43px;
    border: 1px solid #eecf62;
    padding-top: 4px;
    cursor: pointer;
}

.b-input{background-color: #ffffff !important; width: calc(100% - 218px) !important;
    padding: 4px 0px 6px 12px !important; color: #999999 !important; font-size: 19.6px !important;
}
.b-input::-webkit-input-placeholder{color: #999999 !important; font-size: 19.6px !important;}
.b-input{background-color: #ffffff !important; width: calc(100% - 218px) !important;
    padding: 4px 0px 6px 12px !important; color: #999999 !important; font-size: 19.6px !important;
}
.b-input::-webkit-input-placeholder{color: #999999 !important; font-size: 19.6px !important;}

.b-select {
    background-color: #ffffff !important;
    width: calc(100% - 204px) !important;
    padding: 4px 0px 6px 0px !important;
    color: #999999 !important;
    font-size: 19.6px !important;
}

.menu-profile {
    border-left: 1px solid #252525;
    width: calc(100% - 292px);
    float: right;
    padding: 55px 30px 0px 30px;}
.p-div{background-color: rgba(255,255,255,0.1); position: relative;
    border-radius: 4px;padding: 28px 28px 0px 42px; height: 462px;}    
.p-edit-name{float: right; font-size: 12px; color: #ffffff; cursor: pointer;
    font-family: Roboto-Medium;}
.p-table{margin-top: 8px;}    
.p-head{font-family: BlenderPro-Book; font-size: 16px; color: #959494;padding-bottom: 28px; text-align: left;}
.p-detail{font-family: BlenderPro-Medium; font-size: 16px; color: #ffffff;padding-bottom: 28px;
    padding-left: 33px;}
.p-edit-div{background-color: rgba(255,255,255,0.1); position: relative;
        border-radius: 4px;padding: 35px 115px 0px 45px; height: 462px;}  
.div-name{float: left;}
.div-name p{font-size: 16px; font-family: ProximaNova-Regular; color: #ffffff; margin-bottom: 16.5px;}
.div-name input{padding: 0px 12px;
    width: 347px;
    font-size: 14.1px;
    color: #ffffff;
    font-family: BlenderPro-Book;
    background-color: transparent;
    border: 1px solid #ffffff;
    height: 34.6px;}
.div-email{float: right;}
.div-email p{font-size: 16px; font-family: ProximaNova-Regular; 
    color: #ffffff; margin-bottom: 16.5px;}
.div-email input{padding: 0px 12px;
    width: 347px;
    font-size: 14.1px;
    color: #ffffff;
    font-family: BlenderPro-Book;
    background-color: transparent;
    border: 1px solid #ffffff;
    height: 34.6px;}
.div-num{float: left;}      
.span-num{padding: 9px 6px 11px 12px;
    border: 1px solid #ffffff;
    color: #ffffff;
    font-family: BlenderPro-Book;
    font-size: 14.1px;
    border-right: none;}  
.div-num p{font-size: 16px; font-family: ProximaNova-Regular; color: #ffffff; margin-bottom: 16.5px;}        
.div-num input{padding: 0px 12px 0px 0px;
    width: 320px;
    font-size: 14.1px;
    border-left: none !important;
    color: #ffffff;
    font-family: BlenderPro-Book;
    background-color: transparent;
    border: 1px solid #ffffff;
    height: 34.6px;}
.details{margin-bottom: 109px;}    
.save-btn{height: 36px;
    position: absolute;
    background-color: #eecf62;
    color: #000000;
    padding: 9px 0px 8px 0px;
    width: 145px;
    font-size: 14px;
    font-family: Roboto-Bold;
    border: 1px solid #eecf62;
    top: 267px;
    bottom: 0;
    left: 46px;
    right: 0;}
.p-tx{overflow: hidden; border-bottom: 0.7px solid rgba(255, 255, 255, 0.24); padding: 14px 17px 11px 20px;}
.p-tx p:first-child{color: #ffffff; float: left; font-size: 14px; font-family: BlenderPro-Book;}
.p-amt{padding: 12.5px 20px 17px 20px; overflow: hidden;}
.p-tx p:nth-child(2n){float: right; font-family: BlenderPro-Book; font-size: 14px; color: rgba(255, 255, 255, 0.50);}
.p-lft{float: left;}
.p-lft p:first-child{color: #ffffff; font-family: BlenderPro-Book; font-size: 20px; }
.p-lft p:nth-child(2n){color: #777777; font-family: BlenderPro-Book; font-size: 14px;}
.p-ryt{font-size: 20px; float: right; font-family: BlenderPro-Medium; color: #eecf62;}
.pb-ticket{margin: 0px 42px 20px 40px; overflow: hidden; border-radius: 4px;
background-color: #131313; border: 0.7px solid #2F2F2F;}
.b-clr-extra{background-color: rgba(255,255,255,0.1) !important; border-radius: 4px; 
    overflow: hidden; padding-top:42px;}
.pb-ticket:last-child{margin: 0px 42px 26px 40px !important;}    
.pb-form{margin-top: 50px; margin-bottom: 50px;}
.css-wmw4vi-ReactDropdownSelect{width: 200px !important; border: 1px solid #272727 !important;
    height: 0px !important; padding: 9px 0px 9px 10px !important; min-height: 32px !important;
    background-color: rgba(255,255,255,0.10);
}
.css-1yc4zyy-DropdownHandleComponent{width: 35px;
    background-color: rgba(60,59,59,0.60);
    height: 32px;
    position: relative;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    left: 0px;
    top: 0px;}
.css-1aarvou-DropdownHandleComponent{background-color: rgba(60,59,59,0.60);
    margin: 0px 10px 0px 5px !important;
    height: 32px;
    width: 35px;
    position: relative;
    left: 10px;}
.m-extra{margin-top: -15px !important;position: relative;
    float: right;
    width: 43% !important;
    overflow: visible;
    clear: both;
    top: 2px;}    
.dd-e{margin-right: 20px;clear: both;
    position: relative;
    top: 17px;}
.pb-coin{ top: -26px !important;}

.css-1yc4zyy-DropdownHandleComponent svg {
    width: 12px !important;
    height: 12px !important;
    position: relative !important;
    top: 8px !important;
    color: #ffffff !important;
}
.css-1aarvou-DropdownHandleComponent svg {
    width: 12px !important;
    height: 12px !important;
    fill: #ffffff !important;
    margin-top: 8px;
}
.css-u534x6-DropDown{background-color: #272727 !important; border: none !important; border-radius: 4px;}
.css-148o527-ItemComponent{border-bottom: none !important; color: rgba(255,255,255,0.80); 
    font-size: 14px; font-family: BlenderPro-Medium; padding: 8px !important;}
.css-o79eln-InputComponent{margin-left: 0px !important; font-family: BlenderPro-Book !important; font-size: 14px;}    
.react-dropdown-select-dropdown{background-color: #272727 !important; border: none !important;}
.css-148o527-ItemComponent:hover{background-color: rgba(60,59,59,0.60) !important;}
.top-0{top: 7px !important;}
.display-show{display:block;}
.display-hide{display:none;}
.date-filter{    position: relative;
    float: left;
    cursor: pointer;
    background-color: #272727;
    padding: 7.5px 14px;
    border-radius: 4px;
    /*top: -32px;*/
    bottom: 0;
    /*left: -460px;*/
    right: 0;}
.date-filter p{float: left; font-family: BlenderPro-Book; margin-right: 6px;
    font-size: 14px; color: #ffffff;}
.date-filter img{float: right; width: 13px; height: 13px; margin-top: 1px;}
.lt-coin{left: 100px;}
.d-f-show{    
    display: none;
    position: absolute;
    margin: 0px auto;
    padding: 18px 9px;
    width: 310px;
    overflow: hidden;
    background-color: #272727;
    border-radius: 4px;
}
.d-f-show p{font-family: BlenderPro-Medium; font-size: 14px; color: rgba(255,255,255,0.80);}
.react-date-picker__wrapper{width: 143px; border-radius: 4px; background-color: #4b4b4b; 
    border: none !important; padding-left: 13px;}
.react-date-picker__clear-button__icon {display: none !important;}
.react-date-picker{width: 145px;}
.dp-div-l{float: left;}
.dp-div-r{float: right;}
.sd-p{margin-bottom: 10px; font-size: 14px; font-family: BlenderPro-Medium; color: rgba(255,255,255,0.80);}
.dp-btn{margin-top: 31px; float: right;}
.dp-af{padding: 6.5px 8.5px; border-radius: 4px; cursor: pointer; background-color: #eecf62; margin-left: 12px;
    font-family: BlenderPro-Medium; color: #000000; font-size: 14px; border: 1px solid #eecf62;}
.dp-cf{padding: 6.5px 8.5px; border-radius: 4px; border: 1px solid #717171; cursor: pointer;
    background-color: transparent; font-family: BlenderPro-Medium; color: #68696a; font-size: 14px;}
.coin-extra-df{
    top: -180px !important;
    left: 0px !important;
}
.dp-left-div{float: left; width: 120px; background-color: #4b4b4b;
     padding:7px 10px 7px 13px; border-radius: 4px;}
.dp-left-div p{float: left;}
.dp-left-div img{float: right; cursor: pointer;}
.dp-right-div{float: right; width: 120px; background-color: #4b4b4b;
    padding:7px 10px 7px 13px; border-radius: 4px;}
.dp-right-div p{float: left;}
.dp-right-div img{float: right; cursor: pointer;}
.sr-div{font-family: BlenderPro-Medium; font-size: 14px; margin-bottom: 10px;
    color: rgba(255,255,255,0.80);}
.df-p{position: relative;
    color: #ffffff;
    margin-left: 10px;
    top: 5px;}    

.DateRangePicker__Date--otherMonth{opacity: 0.8 !important;}
.DateRangePicker__CalendarSelection{background-color: grey !important; 
    border:1px solid #eecf62 !important;}
.DateRangePicker__FullDateStates{background-color: #ffffff !important;}    
.DateRangePicker__Date{border: 0.5px solid #000000 !important;}
.DateRangePicker__WeekdayHeading abbr[title]{color: #ffffff !important;}
.DateRangePicker{margin-top: 10px !important;}
.DateRangePicker__MonthHeaderLabel{color: #ffffff !important;}


.text-white{
    color:#ffffff;

}
.text-golden {
    color: #eecf62;
}
.text-grey {
    color: rgba(255,255,255,0.50) !important;
}
.ex-text{margin-top: 2px; font-family: BlenderPro-Book; font-size: 14px; text-align: right;
    color: rgba(255,255,255,0.50);}
.css-wmw4vi-ReactDropdownSelect span{
    color:white !important;
    font-family: BlenderPro-Medium !important;
}
   

.passbook-container{
    overflow: auto;
    max-height: 385px;
    width: 100%;
    float: right;
}
.passbook-container::-webkit-scrollbar 
{ 
	 width: 8px !important;height: 2px !important; 
	 
}

.passbook-container::-webkit-scrollbar-thumb 
{
    -webkit-border-radius: 100px;
    border-radius: 100px;
    background:rgba(255,255,255,0.10);
    -webkit-box-shadow: inset 0 0 0px rgba(255,255,255,0.10);
   
}
.clickable{
    cursor: pointer;
}
.underline{
    text-decoration: underline;
}
/*----------------checkbox---------------------------*/

.radio-box { 
    position: relative;
    cursor: pointer;
    font-size: 15px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;float: left;}
.radio-text{float: left;color:#43425D;font-size: 12px;margin-top: -2px;}

.radio-box input {opacity: 0;cursor: pointer;}

.radio-checkmark { width: 16px;height: 16px;position: absolute;top: 0;left: 0;
            background: url("../images/off.svg") no-repeat 50% 50% !important;
          background-size: 16px 16px !important;cursor: pointer;}
          
.radio-checkmark:after {content: "";position: absolute;display: none;}

.radio-box input:checked ~ .radio-checkmark:after {display: block;}
            
.radio-box .radio-checkmark:after {width: 16px;height: 16px;position: absolute;
            top: 0;left: 0;
    background: url("../images/on.svg") no-repeat 50% 50% !important;background-size: 16px 16px !important;
            cursor: pointer;}
.gender-input{clear: both;
    overflow: hidden;
}
.gender-option{    position: relative;
    margin-left: 7px;
    font-size: 14px;
    font-family: BlenderPro-Book;
    color: #747373;
    top: 3px;
}

.send-check{width: 140px;
    overflow: hidden;
    float: left;} 
.r-input{overflow: hidden;}    
.r-mobile{font-family: ProximaNova-Regular; font-size: 16px; color: #ffffff; 
    margin-top: 28px; margin-bottom: 6.5px;}    
.g-input{overflow: hidden;}
.r-input-lft{float: left;} 
.r-input-lft-input{width: 267px; color: #ffffff; padding-left: 6px;
    background-color: transparent; height: 34.9px; font-family: BlenderPro-Book; 
    font-size: 14.1px; border: none !important;}  
.r-input-ryt{float: left; margin-left:60px;}
.r-input-ryt-input{width: 267px; border: 0.9px solid #ffffff; color: #ffffff;padding-left: 12px;
    background-color: transparent; height: 34.9px; font-family: BlenderPro-Book; font-size: 14.1px;}    
.r-cash{margin-top: 13px; font-family: ProximaNova-Regular; font-size: 12px; color: #8b8b8b;}    
.r-i-div{border: 0.9px solid #ffffff;}
.r-i-div span{padding-left: 12px; color: #ffffff; font-size: 14.1px; font-family: BlenderPro-Book;}
.re-btn{font-family: BlenderPro-Bold; color: #000000; font-size: 18px; padding:16px 42.5px 9px 42.5px;
    background-color: #eecf62; border: none; margin-top: 32.5px;}
.w-goods{font-family: Roboto-Medium; font-size: 20px; color: #ffffff; margin-top: 42px; margin-bottom: 21px;}    
.w-first{
/* width: 256px; */
width:29.7%;
 text-align: center; 
 float: left;
  margin-bottom: 28.4px;
}
.p-dtls{    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: BlenderPro-Medium;
    color: #ffffff;
    margin-top: 12px;
    font-size: 14px;
    text-align: left; }
.pd-div{background-color: #ffffff; height: 170px; border:1px solid rgba(224, 224, 224, 0.3);}
.pd-div img{ width:100%;height:100%}
.p-points{font-family: BlenderPro-Medium; font-size: 14px; color: #b9b9b9; margin-top: 7px;
    text-align: left;}
.pd-btn{background-color: #eecf62; border: none; width: 100%; font-size: 12px; font-family: BlenderPro-Medium;
     padding: 6.2px 0px 6.7px 0px; margin-top: 12.5px;cursor: pointer;}
.pd-dtls-div{border: 1px solid rgba(224, 224, 224, 0.3); padding: 0px 19.7px 15.6px 19.9px;}     
.w-second{
    /* width: 256px;  */
    width:29.7%;
    text-align: center; 
    float: left; 
    /* margin-left: 63px; */
    margin-left: 5.4%;
    margin-right: 5.4%;
     margin-bottom: 28.5px;
    }
.w-third{
    /* width: 256px;  */
    width:29.7%;
    text-align: center;
     float: left;
      margin-bottom: 28.5px;
    }
.w-first:last-child{margin-bottom: 0px !important;}
.w-second:last-child{margin-bottom: 0px !important;}
.w-third:last-child{margin-bottom: 0px !important;}
.b-dtls{margin-left: 46.8px;
    position: relative;
    font-family: BlenderPro-Medium;
    color: #ffffff;
    font-size: 24px;
    top: -3px;}
.product{background-color: rgba(0,0,0,0.24); border-radius: 4px; border: 0.7px solid rgba(255, 255, 255, 0.1);
    padding: 17px 17px 17px 17px; overflow: hidden; margin-top: 30.2px;}    
.product-lft{margin-right:24px;float: left; padding: 15px 9px 15px 9px; 
    background-color: #ffffff; border-radius: 4px;width:133px;height:104px;}
.product-lft img{width:100%;height: 100%;}
/* .product-ryt{float: left; margin-left: 24px;} */
.product-ryt p{font-family: BlenderPro-Medium; color: #ffffff; 
    font-size: 24px; margin-top: 36px;}
.d-add{color: #ffffff; font-family: BlenderPro-Medium; font-size: 24px; margin-top: 32px;
    color: #ffffff;}
.d-r-top{margin-top: 34.2px;}    
.r-wid{width: 120px !important;}
.rd-name{font-family: ProximaNova-Regular; color: #ffffff; font-size: 16px; 
    margin-top: 28.5px; margin-bottom: 12px;}
.row{overflow: hidden;}
.row-lft{float: left; margin-right: 30px;}
.row-lft input{ width: 264px;
    padding-left: 12.3px;
    border: 0.9px solid #ffffff;
    background-color: transparent;
    font-family: BlenderPro-Book;
    color: #ffffff;
    font-size: 14.1px;
    height: 32.6px;}
.row-ryt input{width: 264px;
    border: 0.9px solid #ffffff;
    padding-left: 12.3px;
    background-color: transparent;
    font-family: BlenderPro-Book;
    color: #ffffff;
    font-size: 14.1px;
    height: 32.6px;}    
.row-ryt{float: left;}
.row-ryt input{background-color: transparent; font-family: BlenderPro-Book; color: #ffffff; font-size: 14.1px;}
.c-btn{margin-top: 42.4px; background-color: #eecf62; width: 145px; height: 36px; padding: 15px 0px 10px 0px;
    font-size: 18px; font-family: BlenderPro-Bold; border: none;}

    .react-dropdown-select-item-selected{
        background:rgba(60,59,59,.6)!important;}
.container-tnc{
    margin:0px auto;padding:0px;color: #e8e8e8;
    background-color:#000000;overflow: auto;height: 100%;
}        
.content-tnc{ margin: 51px 106px 0px 109px;}
.m-head{font-family: BlenderPro-Medium; font-size: 28px; margin-bottom: 36px;}
.mm-head{font-family: BlenderPro-Medium; font-size: 22px; margin-bottom: 14px;}
.mm-data{font-family: BlenderPro-Book; font-size: 17px; margin-bottom: 48px;}
.mm-t-data{font-family: BlenderPro-Book; font-size: 17px; margin-bottom: 16px;}
.mm-data-list{font-family: BlenderPro-Book; font-size: 17px; margin-bottom: 48px; margin-left: 15px;}
.mm-data-list li{font-family: BlenderPro-Book; font-size: 17px;
    color: #e8e8e8;
    padding-left: 5px;
    list-style-type: disc !important;}
.scroll-top{    padding-top: 18px;
    bottom: 20px;
    right: 20px;
    position: fixed;
    z-index: 999999;
    color: #ffffff;
    border-radius: 50%;
    background-color: #000000;
    width: 55px;
    text-align: center;
    padding-bottom: 18px;
    display: none;
    border: 1px solid #ffffff;}    

.hidden{display: none;}    

.scroll-top-r{    padding-top: 18px;
    bottom: 20px;
    right: 40px;
    position: fixed;
    z-index: 999999;
    color: #ffffff;
    border-radius: 50%;
    background-color: #000000;
    width: 55px;
    text-align: center;
    padding-bottom: 18px;
     display: none;
    border: 1px solid #ffffff;}  

    .scrollcontainer-top{    padding-top: 18px;
        bottom: 20px;
        right: 20px;
        position: fixed;
        z-index: 999999;
        color: #ffffff;
        border-radius: 50%;
        background-color: #000000;
        width: 55px;
        text-align: center;
        padding-bottom: 18px;
        display: none;
        border: 1px solid #ffffff;}  
.b-d-extra{float: left;}        
.a-coin-img{float: left;}   
.nav-a{display: none;}
.nav-img{} 
.side-menu-m{display: none;}
.cc-css{
   overflow: visible;
}
.cc-css-l{    width: 44%;
    float: left;
    clear: both;
    overflow: visible;}
.cc-css-r{        width: 53%;
    float: left;
    margin-left: 2%;
}

.up-m{margin-top: 0px;}

.hide-it{display: none;}

}

.up-m{margin-top: 0px;}

.hide-it{display: none;}



/*----------------------------------MEDIA QUERY------------------------------------*/
@media only screen and (max-width: 767px) {
    .blur-bk{
        position: fixed;
        height: 100%;
        width: 100%;
        background-color: rgba(0,0,0,0.5);
        z-index: 99;
        display: none;
    }
    .lg-visible{
        display:none;
    }
    .sm-visible{
        display: block;
    }
.container-menu{
    float: right;
    width: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    margin: 0px auto;
    padding: 0px;
    background-color: #ffffff;
    z-index: 9999999;
    margin-top: 64px !important;
    transition: width 0.5s;
    display: none;
}
.container-menu-m{
    float: right;
    position: absolute;
    top: 0;
    right: 0;
    display: none;
    bottom: 0;
    margin: 0px auto;
    padding: 0px;
    background-color: #ffffff;
    z-index: 999999;
    margin-top: 64px !important;
    transition: width 0.5s;
}
.width-66{
    width: 66%;
    display: block;
}
.container-main{margin:0px auto;padding:0px;
    background-color:#000000;overflow: auto;height: 100%;} 
.container-signup{    margin: 0px auto;
    padding: 0px;
    background-color: #000000;}       
/* .container-logout{margin:0px auto;padding:0px; position: sticky; z-index: 999; 
    top: 0; left: 0; right: 0; bottom: 0;
    background-color:#000000;height: 64px;border-bottom: 1px solid #252525;}     */
.container-logout{    width: calc(100% - 30px);
    margin: 0px auto;
    padding: 19px 16px 11px 13px;
    position: sticky;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000000;
    border-bottom: 1px solid #252525;}    
.header-menu{ overflow: hidden;}   
.pad-extra{}
/* .h-left{float: left; border-right: 1px solid #252525;
    padding: 14px 40px 14px 24px;} */
.h-left{            position: relative;
    border-bottom: 1px solid #252525;
    border-right: 1px solid #252525;
    padding-bottom: 10px;
    float: left;
    padding-top: 14px;
    padding-left: 24px;
    padding-right: 39px;
    overflow: hidden;
    right: 28px;
}    
.h-left img{width:167px; height:36px;}
.h-ryt{ margin-top: 3px;    
    padding: 0px;
    float: right;} 
.logout-btn{    position: absolute;
    font-family: BlenderPro-Bold;
    font-size: 16px;
    border: none;
    display: none;
    background-color: #eecf62;
    cursor: pointer;
    color: #000000;
    padding: 14px 15.5px 8px 15.5px;
    left: 0;
    right: 0;
    bottom: 34px;
    margin: 0px 16px 0px 16px;
    width: calc(100% - 32px);}
.side-menu{
    display: none;
    padding: 0px 13px 0px 29px;
    border-right: 1px solid #252525;
    width: 189px;
    height: 100%;
}    
.menu{color: #7a7e87;
font-family: Roboto-Regular;
font-size: 12px;
margin-bottom: 9px;
padding-top: 41px;
clear: both;
}
.menu-div{ overflow: hidden; margin-top: 12px;}
.sub-m-div{border-bottom: 0.8px solid rgba(0,0,0,0.2);
    padding: 16.5px 0px 16.5px 20px;
    overflow: hidden;
}
.m-logo{width: 20px; height: 20px; float: left;}
.m-data{    font-size: 16px;
    font-family: BlenderPro-Medium;
    color: #606060;
    float: left;
    margin-left: 16px;}
.m-active p{    margin-left: 11px !important;
    color: #eecf62 !important;
    font-family: BlenderPro-Bold !important;
    margin-top: 8px !important;}
.m-active svg:not(.star) path{stroke:#eecf62 !important;}    
.m-active svg:not(.star) rect{stroke:#eecf62 !important;}
.m-active svg.star path:nth-child(2){fill:#eecf62 !important;}    

.menu-auth{width: calc(100% - 32px);
    border-left: none;
    padding: 20.5px 16px 0px 16px;
}
.menu-rdetails{
    width: calc(100% - 32px);
    padding: 18.5px 16px 0px 16px;
}
.menu-redeem{width: calc(100% - 32px);
    padding: 20.5px 16px 50px 16px;
}
.m-heading{font-family: BlenderPro-Bold; font-size: 22px; color: #ffffff; margin-top: 5px;}
.coins{overflow: hidden;
    padding: 11px 0px 8px 16px;
    border: 1px solid #333333;
    margin-top: 16px;
    background-color: #1b1b1b;
    border-radius: 4px;}
.coins img{    
    margin-top: 9px;
    width: 22px;
    height: 22px;
    margin-left: 10px;}
.coins p{position: relative;
    width: calc(100% - 60px);
    height: 62px;
    font-size: 16px;
    font-family: BlenderPro-Book;
    color: #fff;
    float: left;
    margin-left: 12px;
    margin-top: -13px;
}
.tp-text{
    top: 13px !important;
    left: 0 !important;
    float: left !important;
    font-size: 16px !important;
    font-family: BlenderPro-Book !important;
    color: #fff !important;
    width: 100% !important;
    position: relative !important;
    line-height: normal !important;
}
.coins-span{
    line-height: 45px !important;
    position: absolute !important;
    font-size: 27px !important;
    color: #eecf62 !important;
    font-family: BlenderPro-Bold !important;
    top: 28px !important;
    left: 0px !important;
    overflow: visible !important;
    right: 0 !important;
    bottom: 0 !important;
   /* z-index: 999 !important;*/
}
.p-code{font-family: BlenderPro-Book; font-size: 16px; color: #ffffff;}
.submit-btn{    
    width: 49%;
    position: relative;
    left:320px;
    top:-32px;
    height: 36px;
    margin-top: 30px;
    background-color: #eecf62;
    color: #000000;
    font-size: 18px;
    cursor: pointer;
    padding-top: 5px;
    font-family: BlenderPro-Bold;
    border: 1px solid #eecf62;}

.input-code{    
    color: #ffffff;
    font-family: BlenderPro-Book;
    font-size: 14px;
    /* float: left; */
    width: calc(100% - 26px);
    margin-top: 16px;
    padding: 9px 12px 9px 12px;
    border: 1px solid #ffffff;
    background-color: transparent;}

    .select-code {
        color: #ffffff;
        font-family: BlenderPro-Book;
        font-size: 14px;
        /* float: left; */
        width: 296px;
        margin-top: 16px;
        padding: 7px 12px 7px 0px;
        border: 1px solid #ffffff;
        background-color: transparent;
    }
/* .input-cmb{
    float: left;
    margin-bottom: 32px !important;
}     */
.back-img{width: 24px; height: 24px;cursor: pointer;}    
.auth-form{margin-top: 16px;}
.rdetails-form{background-color: rgba(255,255,255,0.1); padding: 0px; 
    overflow: hidden; margin-bottom: 54px;border-radius: 4px;}
.redeem-form{margin-top: 16px;}
.profile-form{margin-top: 20px;}
.f-form{background-color: rgba(255,255,255,0.1); position: relative;
    border-radius: 4px;padding:21.5px 16px 30px 16px;}
    
.r-form{background-color: rgba(255,255,255,0.1); position: relative;
        border: 1px solid #333333;
        border-radius: 4px;padding: 15px 16px 14px 16px;}    

.w-goods-div{ overflow: hidden;
        
        /* padding: 27.5px 37px 0px 33px;  */
    }       
.re-d-ee{    
    margin-bottom: 14px;
    margin-top: 41px;
    
}
      
.div-sign-up{    top: 11%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    } 
.signUp-form{margin: 235px 16px 0px 16px;;
    border-radius: 6px;
   }     
.s-text{font-family: BlenderPro-Medium; font-size: 24px; color: #ffffff; text-align: left;}      
.signUp-form input{border: 1px solid #ffffff;
    padding: 8px 0px 8px 12px;
    font-size: 14.1px;
    font-family: BlenderPro-Book;
    margin-top: 16px;
    color: #ffffff;
    background-color: transparent;
   
    width: calc(100% - 16px);}
 .div-checkbox{  
   /*   margin-top: 17.4px; */
    }    
.checkbox-img{float: left;
    width: 17px;
    height: 17px;}
.tnc{margin-left: 27px;
    font-family: BlenderPro-Book;
    font-size: 14px;
    color: #ffffff;}
.tnc a{
    font-family: BlenderPro-Book;
    font-size: 14px;
    color: #ffffff;text-decoration: underline;}    
.s-btn{  
    margin-top: 30px;
    width: 120px;
    background-color: #eecf62;
    color: #000000;
    font-family: BlenderPro-Bold;
    font-size: 19px;
    height: 36px;
    /* margin-left: 43px; */
    border: 1px solid #eecf62;
    padding-top: 4px;
    cursor: pointer;
}
.acc-text{font-size: 14px;
    font-family: ProximaNova-Regular;
    color: #ffffff;
    margin-top: 32px;
     }
.shift-u{float: right;}

.acc-text span{font-family: Proxima-Nova-Bold !important; text-decoration: underline; cursor: pointer;}
.login-form{margin: 237px 16px 0px 16px;
    /* background-color: rgba(0, 0, 0, 0.56); */
    border-radius: 6px;
   
    /* border: 1px solid rgba(255, 255, 255, 0.15); */
   }
.p-code-form{
    background-color: transparent !important;
    border: none !important;
    margin: 273px 16px 0px 16px !important;
}    
.login-form input,
.login-form .custom-select {
    border: 1px solid #ffffff;
    padding: 9px 0px 8px 12px;
    font-size: 14px;
    font-family: BlenderPro-Book;
    margin-top: 12px;
    color: #ffffff;
    background-color: transparent;
    /* margin-left: 43px; */
    /* width: calc(100% - 104px); */
    width: calc(100% - 2px);}    
.enter-num{    margin-top: 16px;font-family: ProximaNova-Regular; font-size: 14px; color: #ffffff;}    
.r-btn{  
      /* margin-bottom: 23px; */
    margin-top: 21.4px;
    width: 180px;
    background-color: #eecf62;
    color: #000000;
    font-family: BlenderPro-Bold;
    font-size: 19px;
    height: 36px;
    border: 1px solid #eecf62;
    padding-top: 5px;
    cursor: pointer;
}
.mar-tp-16{margin-top: 16.5px !important;}
.f-lft{text-align: left; }
.enter-code{ margin-top: 13px;font-family: ProximaNova-Regular; font-size: 16px; color: #ffffff;}    
.wid-code{width: 288px;}
.v-btn{    margin-bottom: 28.5px;
    margin-top: 21.4px;
    width: 120px;
    background-color: #eecf62;
    color: #000000;
    font-family: BlenderPro-Bold;
    font-size: 15px;
    height: 36px;
    border: 1px solid #eecf62;
    padding-top: 4px;
    cursor: pointer;
}
.b-input{background-color: #ffffff !important; width: calc(100% - 16px) !important;
    padding: 4px 0px 6px 12px !important; color: #999999 !important; font-size: 19.6px !important;
}
.b-input::-webkit-input-placeholder{color: #999999 !important; font-size: 19.6px !important;}

.b-select {
    background-color: #ffffff !important;
    padding: 4px 0px 6px 0px !important;
    color: #999999 !important;
    font-size: 19.6px !important;
}

.menu-profile {
    width: calc(100% - 32px);
    padding: 20.5px 16px 0px 16px;}
.p-div{background-color: rgba(255,255,255,0.1); position: relative; border: 1px solid #333333;
    border-radius: 4px;padding: 16px 16px 0px 16px;}    
.p-edit-name{float: right; font-size: 14px; color: #ffffff; cursor: pointer;
    font-family: BlenderPro-Medium; margin-top: -54px;}
.p-table{margin-top: 8px; width: 100%;}    
.p-head{font-family: BlenderPro-Book; font-size: 16px; color: #959494;padding-bottom: 28px; text-align: left;}
.p-detail{font-family: BlenderPro-Medium; font-size: 16px; color: #ffffff;padding-bottom: 28px;
    padding-left: 5%; word-break: break-word;}
.p-edit-div{background-color: rgba(255,255,255,0.1); position: relative;
        border-radius: 4px;padding: 16px 16px 0px 16px; height: auto;}  
.div-name{}
.div-name p{font-size: 16px; font-family: ProximaNova-Regular; margin-top: 28px;
    color: #ffffff; margin-bottom: 16.5px;}
.me-0{margin-top: 0px !important;}    
.div-name input{padding: 0px 12px;
    width: calc(100% - 32px);
    font-size: 14.1px;
    color: #ffffff;
    border-radius: 0px;
    font-family: BlenderPro-Book;
    background-color: transparent;
    border: 1px solid #ffffff;
    height: 34.6px;}
.div-email{}
.div-email p{font-size: 16px; font-family: ProximaNova-Regular; color: #ffffff; margin-top: 28px;
    margin-bottom: 16.5px;}
.div-email input{padding: 0px 12px;
    width: calc(100% - 32px);
    font-size: 14.1px;
    border-radius: 0px;
    color: #ffffff;
    font-family: BlenderPro-Book;
    background-color: transparent;
    border: 1px solid #ffffff;
    height: 34.6px;}
.div-num{float: left;}      
.span-num{padding: 9px 6px 11px 12px;
    border: 1px solid #ffffff;
    color: #ffffff;
    font-family: BlenderPro-Book;
    font-size: 14.1px;
    border-right: none;}  
.div-num p{font-size: 16px; font-family: ProximaNova-Regular; color: #ffffff; margin-bottom: 16.5px;}        
.div-num input{padding: 0px 12px 0px 0px;
    width: 320px;
    font-size: 14.1px;
    border-left: none !important;
    color: #ffffff;
    font-family: BlenderPro-Book;
    background-color: transparent;
    border: 1px solid #ffffff;
    height: 34.6px;}
.details{}    
.save-btn{height: 36px;
    background-color: #eecf62;
    color: #000000;
    padding: 9px 0px 8px 0px;
    width: 145px;
    font-size: 14px;
    font-family: Roboto-Bold;
    border: 1px solid #eecf62;
    margin-bottom: 42px;
    margin-top: 40px;
    }
.p-tx{overflow: hidden; border-bottom: 0.7px solid rgba(255, 255, 255, 0.24); padding: 14px 17px 11px 20px;}
.p-tx p:first-child{color: #ffffff; float: left; font-size: 14px; font-family: BlenderPro-Book;}
.p-amt{padding: 12.5px 20px 17px 20px; overflow: hidden;}
.p-tx p:nth-child(2n){float: right; font-family: BlenderPro-Book; font-size: 14px; color: rgba(255, 255, 255, 0.50);}
.p-lft{float: left;}
.p-lft p:first-child{color: #ffffff; font-family: BlenderPro-Book; font-size: 20px; }
.p-lft p:nth-child(2n){color: #777777; font-family: BlenderPro-Book; font-size: 14px;}
.p-ryt{font-size: 20px; float: right; font-family: BlenderPro-Medium; color: #eecf62;}
.pb-ticket{margin: 0px; overflow: hidden; border-radius: 4px;
background-color: #131313; border: 0.7px solid #2F2F2F; margin-bottom: 16px !important;}
.b-clr-extra{border-radius: 4px; overflow: hidden;}
.pb-ticket:last-child{margin: 0px !important;}    
.pb-form{margin-top: 34px; margin-bottom: 50px;}
.css-wmw4vi-ReactDropdownSelect{width: 61% !important; border: 1px solid #272727 !important;
    height: 0px !important; padding: 9px 0px 9px 10px !important; min-height: 32px !important;
    background-color: rgba(255,255,255,0.10);
}
.css-1yc4zyy-DropdownHandleComponent{width: 35px;
    background-color: rgba(60,59,59,0.60);
    height: 32px;
    position: relative;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    left: 0px;
    top: 0px;}
.css-1aarvou-DropdownHandleComponent{background-color: rgba(60,59,59,0.60);
    margin: 0px 10px 0px 5px !important;
    height: 32px;
    width: 35px;
    position: relative;
    left: 10px;}
.m-extra{margin-top: 13px !important;}    
.dd-e{margin-right: 20px; margin-top: 43px;}
.pb-coin{ top: -26px !important;}

.css-1yc4zyy-DropdownHandleComponent svg {
    width: 12px !important;
    height: 12px !important;
    position: relative !important;
    top: 8px !important;
    color: #ffffff !important;
}
.css-1aarvou-DropdownHandleComponent svg {
    width: 12px !important;
    height: 12px !important;
    fill: #ffffff !important;
    margin-top: 8px;
}
.css-u534x6-DropDown{background-color: #272727 !important; border: none !important; border-radius: 4px;}
.css-148o527-ItemComponent{border-bottom: none !important; color: rgba(255,255,255,0.80); 
    font-size: 14px; font-family: BlenderPro-Medium; padding: 8px !important;}
.css-o79eln-InputComponent{margin-left: 0px !important; font-family: BlenderPro-Book !important; font-size: 14px;}    
.react-dropdown-select-dropdown{background-color: #272727 !important; border: none !important;}
.css-148o527-ItemComponent:hover{background-color: rgba(60,59,59,0.60) !important;}
.date-filter{    position: relative;
    float: right;
    cursor: pointer;
    width: 27%;
    background-color: #272727;
    padding: 7.5px 14px;
    border-radius: 4px;
    top: -32px;
    bottom: 0;
    left: 0px;
    right: 0;}
.date-filter p{float: left; font-family: BlenderPro-Book; margin-right: 6px;
    font-size: 14px; color: #ffffff;}
.date-filter img{float: right; width: 13px; height: 13px; margin-top: 1px;}
.lt-coin{left: 100px;}
.d-f-show{    
    display: none;
    position: absolute;
    margin: 0px auto;
    padding: 18px 9px;
    width: calc(100% - 50px);
    overflow: hidden;
    background-color: #272727;
    border-radius: 4px;
    margin-top: 5px !important;
    left: 16px !important;
}
.d-f-show p{font-family: BlenderPro-Medium; font-size: 14px; color: rgba(255,255,255,0.80);}
.react-date-picker__wrapper{width: 143px; border-radius: 4px; background-color: #4b4b4b; 
    border: none !important; padding-left: 13px;}
.react-date-picker__clear-button__icon {display: none !important;}
.react-date-picker{width: 145px;}
.dp-div-l{float: left;}
.dp-div-r{float: right;}
.sd-p{margin-bottom: 10px; font-size: 14px; font-family: BlenderPro-Medium; color: rgba(255,255,255,0.80);}
.dp-btn{margin-top: 31px; float: right;}
.dp-af{padding: 6.5px 8.5px; border-radius: 4px; cursor: pointer; background-color: #eecf62; margin-left: 12px;
    font-family: BlenderPro-Medium; color: #000000; font-size: 14px; border: 1px solid #eecf62;}
.dp-cf{padding: 6.5px 8.5px; border-radius: 4px; border: 1px solid #717171; cursor: pointer;
    background-color: transparent; font-family: BlenderPro-Medium; color: #68696a; font-size: 14px;}
.coin-extra-df{
    top: -180px !important;
    left: 0px !important;
}
.dp-left-div{float: left; width: 39%; background-color: #4b4b4b;
     padding:7px 10px 7px 13px; border-radius: 4px;}
.dp-left-div p{float: left;}
.dp-left-div img{float: right; cursor: pointer;}
.dp-right-div{float: right; width: 39%; background-color: #4b4b4b;
    padding:7px 10px 7px 13px; border-radius: 4px;}
.dp-right-div p{float: left;}
.dp-right-div img{float: right; cursor: pointer;}
.sr-div{font-family: BlenderPro-Medium; font-size: 14px; margin-bottom: 10px;
    color: rgba(255,255,255,0.80);}
.df-p{    position: absolute;
    color: #ffffff;
    top: 51px;
    left: 49%;
    right: 0;}    

.DateRangePicker__Date--otherMonth{opacity: 0.8 !important;}
.DateRangePicker__CalendarSelection{background-color: grey !important; 
    border:1px solid #eecf62 !important;}
.DateRangePicker__FullDateStates{background-color: #ffffff !important;}    
.DateRangePicker__Date{border: 0.5px solid #000000 !important;}
.DateRangePicker__WeekdayHeading abbr[title]{color: #ffffff !important;}
.DateRangePicker{margin-top: 10px !important;}
.DateRangePicker__MonthHeaderLabel{color: #ffffff !important;}


.text-white{
    color:#ffffff;

}
.text-golden {
    color: #eecf62;
}
.text-grey {
    color: rgba(255,255,255,0.50) !important;
}
.ex-text{margin-top: 2px; font-family: BlenderPro-Book; font-size: 14px; text-align: right;
    color: rgba(255,255,255,0.50);}
.css-wmw4vi-ReactDropdownSelect span{
    color:white !important;
    font-family: BlenderPro-Medium !important;
}
   

.passbook-container{
    width: 100%;
    overflow: auto;
    max-height: 400px;
}
.passbook-container::-webkit-scrollbar 
{ 
	 width: 8px !important;height: 2px !important; 
	 
}

.passbook-container::-webkit-scrollbar-thumb 
{
    -webkit-border-radius: 100px;
    border-radius: 100px;
    background:rgba(255,255,255,0.10);
    -webkit-box-shadow: inset 0 0 0px rgba(255,255,255,0.10);
   
}
.clickable{
    cursor: pointer;
}
/*----------------checkbox---------------------------*/

.radio-box { 
    position: relative;
    cursor: pointer;
    font-size: 15px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;float: left;}
.radio-text{float: left;color:#43425D;font-size: 12px;margin-top: -2px;}

.radio-box input {opacity: 0;cursor: pointer;}

.radio-checkmark { width: 16px;height: 16px;position: absolute;top: 0;left: 0;
            background: url("../images/off.svg") no-repeat 50% 50% !important;
          background-size: 16px 16px !important;cursor: pointer;}
          
.radio-checkmark:after {content: "";position: absolute;display: none;}

.radio-box input:checked ~ .radio-checkmark:after {display: block;}
            
.radio-box .radio-checkmark:after {width: 16px;height: 16px;position: absolute;
            top: 0;left: 0;
    background: url("../images/on.svg") no-repeat 50% 50% !important;background-size: 16px 16px !important;
            cursor: pointer;}
.gender-input{clear: both;
    overflow: hidden;
}
.gender-option{    position: relative;
    margin-left: 7px;
    font-size: 14px;
    font-family: Roboto-Regular;
    color: #747373;
    top: 1px;
}
.send-check{width: 52%;
    overflow: hidden;
    float: left;} 
.r-input{overflow: hidden;}    
.r-mobile{font-family: BlenderPro-Book; font-size: 16px; color: #ffffff; 
    margin-top: 28px; margin-bottom: 6.5px;}    
.g-input{overflow: hidden;}
.r-input-lft{float: none;} 
.r-input-lft-input{width: calc(100% - 48px); color: #ffffff; padding-left: 6px;
    background-color: transparent; height: 34.9px; font-family: BlenderPro-Book; 
    font-size: 14.1px; border: none !important;}  
.r-input-ryt{float: none;}
.re-btn-div{text-align: center;}
.bg-black{background-color: #000000;}
.r-input-ryt-input{width: calc(100% - 14px); border: 0.9px solid #ffffff; color: #ffffff;padding-left: 12px;
    background-color: transparent; height: 34.9px; font-family: BlenderPro-Book; font-size: 14.1px;}    
.r-cash{margin-top: 13px; font-family: ProximaNova-Regular; font-size: 12px; color: #8b8b8b;}    
.r-i-div{border: 0.9px solid #ffffff;}
.r-i-div span{padding-left: 12px; color: #ffffff; font-size: 14.1px; font-family: BlenderPro-Book;}
.re-btn{font-family: BlenderPro-Bold; color: #000000; font-size: 18px; padding:16px 42.5px 9px 42.5px;
    background-color: #eecf62; border: none; margin-top: 32.5px;}
.w-goods{display: none;font-family: Roboto-Medium; font-size: 20px; color: #ffffff; margin-top: 42px; margin-bottom: 21px;}    
.w-first{width: 48%; text-align: center; float: left; margin-bottom: 28.4px;}
.p-dtls{    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: BlenderPro-Medium;
    color: #ffffff;
    margin-top: 12px;
    font-size: 14px;
    text-align: left; }
.pd-div{background-color: #ffffff; height: 94px; border:1px solid rgba(224, 224, 224, 0.3);}
.pd-div img{ width:80%;height: 100%;}
.p-points{font-family: BlenderPro-Medium; font-size: 14px; color: #b9b9b9; margin-top: 7px;
    text-align: left;}
.pd-btn{background-color: #eecf62; border: none; width: 100%; font-size: 12px; font-family: BlenderPro-Medium;
     padding: 6.2px 0px 6.7px 0px; margin-top: 12.5px;cursor: pointer;display: none;}
.pd-dtls-div{border: 1px solid rgba(224, 224, 224, 0.3); padding: 0px 19.7px 15.6px 19.9px;}     
.w-second{width: 48%; text-align: center; float: right;}
.w-third{width: 256px; text-align: center; float: right; margin-bottom: 28.5px;}
.w-first:last-child{margin-bottom: 0px !important;}
.w-second:last-child{margin-bottom: 0px !important;}
.w-third:last-child{margin-bottom: 0px !important;}
.rd-p-ee{padding: 20px 15px 64px 15px;}
.b-dtls{margin-left: 40px;
    position: relative;
    font-family: BlenderPro-Bold;
    color: #ffffff;
    font-size: 22px;
    padding-bottom: 28px;
    top: 4px;
    line-height: 20px;}
.product{background-color: rgba(0,0,0,1);
    border-radius: 4px;
    padding: 0px;
    overflow: hidden;
}    
.product-lft{    margin-right: 0px;
    /*padding: 15px 0px 15px 0px;*/
    text-align: center;
    background-color: #ffffff;
    border-radius: 4px;
}
.product-lft img{height: 100%; width: 100%;}
.product-ryt{margin-bottom: 38px;}
.product-ryt p{font-family: BlenderPro-Medium;
    color: #ffffff;
    font-size: 20px;
    margin-top: 36px;}
.d-add{color: #ffffff; font-family: BlenderPro-Medium; font-size: 24px;
    color: #ffffff;}
.d-r-top{margin-top: 34.2px;}    
.r-wid{width: 120px !important;}
.rd-name{font-family: ProximaNova-Regular; color: #ffffff; font-size: 16px; 
    margin-top: 28.5px; margin-bottom: 12px;}
.row{overflow: hidden;}
.row-lft{}
.row-lft input{ width: calc(100% - 14px);
    padding-left: 12.3px;
    border: 0.9px solid #ffffff;
    background-color: transparent;
    font-family: BlenderPro-Book;
    color: #ffffff;
    font-size: 14.1px;
   /* height: 32.6px;*/
    padding-top: 9px;
    padding-bottom: 9px;
    box-shadow: none;
    background: transparent;
    background-image: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}
/*.row-ryt input{width: calc(100% - 14px);
    border: 0.9px solid #ffffff;
    padding-left: 12.3px;
    background-color: transparent;
    font-family: BlenderPro-Book;
    color: #ffffff;
    font-size: 14.1px;
    height: 32.6px;}    */
    .row-ryt input{
        font-family: BlenderPro-Book;
        color: #ffffff;
        font-size: 14.1px;
        width: calc(100% - 14px);
        padding-left: 12.3px;
        border: .9px solid #fff;
        padding-top: 9px;
        padding-bottom: 9px;
        box-shadow: none;
        background: transparent;
        background-image: none;
        -webkit-appearance: none;
        -moz-appearance: none;
    }
.row-ryt{}
/*.row-ryt input{background-color: transparent; font-family: BlenderPro-Book; color: #ffffff; font-size: 14.1px;}*/
.c-btn{margin-top: 42.4px; background-color: #eecf62; width: 145px; height: 36px; padding: 15px 0px 10px 0px;
    font-size: 18px; font-family: BlenderPro-Bold; border: none;}

    .react-dropdown-select-item-selected{
        background:rgba(60,59,59,.6)!important;}
.container-tnc{
    margin:0px auto;padding:0px;color: #e8e8e8;
    background-color:#000000;overflow: auto;height: 100%;
}        
.content-tnc{ margin: 20px 16px 0px 16px;}
.m-head{font-family: BlenderPro-Medium; font-size: 22px; margin-bottom: 25px;}
.mm-head{font-family: BlenderPro-Medium; font-size: 17px; margin-bottom: 15px;}
.mm-data{font-family: BlenderPro-Book; font-size: 14px; margin-bottom: 48px;}
.mm-t-data{font-family: BlenderPro-Book; font-size: 17px; margin-bottom: 16px;}
.mm-data-list{font-family: BlenderPro-Book; font-size: 17px; margin-bottom: 48px; margin-left: 15px;}
.mm-data-list li{font-family: BlenderPro-Book; font-size: 17px;
    color: #e8e8e8;
    padding-left: 5px;
    list-style-type: disc !important;}
.scroll-top{    padding-top: 18px;
    bottom: 20px;
    right: 20px;
    position: fixed;
    z-index: 999999;
    color: #ffffff;
    border-radius: 50%;
    background-color: #000000;
    width: 55px;
    text-align: center;
    padding-bottom: 18px;
    display: none;
    border: 1px solid #ffffff;}    

.hidden{display: none;}    

.scroll-top-r{    padding-top: 18px;
    bottom: 20px;
    right: 40px;
    position: fixed;
    z-index: 999999;
    color: #ffffff;
    border-radius: 50%;
    background-color: #000000;
    width: 55px;
    text-align: center;
    padding-bottom: 18px;
     display: none;
    border: 1px solid #ffffff;}  

    .scrollcontainer-top{    padding-top: 18px;
        bottom: 20px;
        right: 20px;
        position: fixed;
        z-index: 999999;
        color: #ffffff;
        border-radius: 50%;
        background-color: #000000;
        width: 55px;
        text-align: center;
        padding-bottom: 18px;
        display: none;
        border: 1px solid #ffffff;}  
.b-d-extra{float: left;}     
.a-coin-img{float: left;
    background-color: #333333;
    border-radius: 50%;
    height: 40px;
    width: 40px;}   
.coins p span{position: relative;
    font-size: 27px;
    color: #eecf62;
    font-family: BlenderPro-Bold;
    top: 29px;
    left: -83px;
    overflow: visible;
    right: 0;
    bottom: 0;
    line-height: 45px;
}  
.b-ee{text-align: center;} 
.b-ee-btn{
    position: relative !important;
    top: 30px !important;
    left: -375px !important;
} 
.nav-a{}
.nav-img{width: 29px; height: 20px;}  
.h-left-m{float: left;}
.h-left-m img{width: 130px; height: 28px;}
.side-menu-m{    
    padding: 0px;
    height: 100%;
    overflow: hidden;}
.display-show{display:none;}
.display-hide{display:block;}
.top-0{display: none;}
.wd-pb{width: calc(100% - 20px) !important;}
.f-ryt{float: right !important;}
.re-dropdown{overflow: hidden;}
.re-dropdown .m-heading{float: left;}
.re-dropdown img{    width: 10px;
    float: right;
    margin-top: 6px;
    height: 5px;
}
.wd-sc{width: 45% !important;}

.up-m{margin-top: 16px;}

.hide-it{display: block;}
.arrowDown{
    width: 5px !important;
    height: 10px !important;
}
.blur-bk-m{
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 9999;
    display: none;
    top: 64px;
}
.menu{position: fixed;}
}

textarea,
input[type="text"],input[type="number"]
 {
     -webkit-appearance: none;
     border-radius: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.div-purchase{
    overflow: hidden;
    float: rght;
    float: left;
    margin-left: 55px;
    margin-top: 20px;
}


@media only screen and (max-width: 767px) {
    .authResposive {
        padding: 0 16px !important;
        top: 520px !important;
    }
    .authResposive p.auth-pp {
        display: none;
    }
    .authResposive .auth-box {
        margin: 0;
        padding: 16px 10px;
        text-align: center;
        background-color: rgb(237 27 36 / 65%);
    }
    .productAuthForm {
        top: 530px !important;
    }
    .adjustPositionTips {
        top: 455px !important;
    }
    .productAuthForm .p-code-form {
        margin: 0px 16px 0px 16px !important;
    }
    .adjustPositionVideo {
        margin-top: 455px !important;
    }
}

@media only screen and (max-width: 400px) {
    .authResposive {
        top: 495px !important;
    }
    .productAuthForm {
        top: 505px !important;
    }
}

@media only screen and (max-width: 360px) {
    .authResposive {
        top: 460px !important;
    }
    .productAuthForm {
        top: 470px !important;
    }
}