*{margin: 0;padding: 0;}
@font-face{font-family: BlenderPro-Bold;src: url(BlenderPro-Bold.ttf);font-weight: normal;font-style: normal;}
@font-face{font-family: BlenderPro-Medium;src: url(Blender-Pro-Medium.otf);font-weight: normal;font-style: normal;}
@font-face{font-family: BlenderPro-Book;src: url(Blender-Pro-Book.otf);font-weight: normal;font-style: normal;}
@font-face{font-family: Roboto-Regular;src: url(Roboto-Regular.ttf);font-weight: normal;font-style: normal;}

html{width: 100%;height: 100%;}
body {font-family: BlenderPro-Bold;width: 100%;height: 100%;margin: 0 auto;

}
a {text-decoration: none;border: none;color: #000;font-family: BlenderPro-Bold;}
img {text-decoration: none;border: none;}
ul {list-style: none;}
li {list-style-type: none;}
input{outline: none;font-family: BlenderPro-Bold;}
button{outline: none;font-family: BlenderPro-Bold;}
select{outline: none;font-family: BlenderPro-Bold;}
textarea{outline: none;font-family: BlenderPro-Bold;}

::-webkit-input-placeholder { /* Chrome */ color: rgb(255,255,255); }
:-ms-input-placeholder { /* IE 10+ */ color:rgb(255,255,255); }
::-moz-placeholder { /* Firefox 19+ */ color: rgb(255,255,255); }
:-moz-placeholder { /* Firefox 4 - 18 */ color: rgb(255,255,255); }

#root{height: 100%;}
.App{height:100%}



@media screen and (min-width: 768px) {
    
.modal-wap {
        display: none !important;
    }

.modal-web {
    display: block !important;
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.7);
    padding: 0px;
    height: 100%;
}



.img-center{
    width: 100%;
    height: 100%;
}
/* .p-modal-content{
    width: 614px !important;
    height: 220px !important;
    padding: 30px 30px 25px 32px !important;

} */

.p-modal-content {
    /* height: 355px !important; */
    width: 77.5% !important;
    height: 393px!important;
    padding: 34px 30px 0 32px!important;
} 

.modal-content {
    padding: 22px 22px 24px 32px;
    top: 15%;
    background-color: #fff;
    margin: auto;
    border: 1px solid #fff;
    width: 480px;
    position: relative;
    border-radius: 4px;
    overflow: auto;
    border-radius: 8px;
}

.modal-content::-webkit-scrollbar 
{ 
     width: 8px !important;height: 8px !important; 
     background-color: #EFEFF4;
}
.modal-content::-webkit-scrollbar-thumb 
{
    -webkit-border-radius: 100px;
    border-radius: 100px;
    background: #B4B4BC;
    -webkit-box-shadow: inset 0 0 0px  #B4B4BC;
   
}

/* .popup-container{
    height: 110px;
    width: 640px;
} */

.popup-container{
    height: 225px;
    /* width: 640px; */
   
}

/* .popup-input{
    float: left;
    border: solid;
    border-color: #DCDCDC; */
    /* padding: 10px 10px 10px 10px; */
   /* height: 96px;
    width: 142px;
    margin-right: 12px;
    background: #E3DFDF 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
    height: 100%;
    width: 100%;
} */

.popup-input {
    background: #e3dfdf 0 0 no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
    height: 274px;
    width: 100%;
}

.p-modal-button{
    width : 136px !important;
    position: relative;
    /* right: 0px; */
    top: 45px;
}

/* .modal-button{float: right;
    margin: 0px auto;
    margin-top: 24px !important;
} */

.modal-button{float: right;
    margin: 0px auto;
    margin-top: 20px !important;
}

.close-img{float: right; width: 12px; height: 12px;}
.c-text{font-family: BlenderPro-Bold; font-size: 22px; color: #000000; margin-top: 12px;
}
.p-desc{font-family: BlenderPro-Book; font-size: 17px; color: #000000; margin-top: 24px;white-space: pre-wrap;}
.m-btn{    width: 91px;
    height: 36px;
    cursor: pointer;
    background-color: #eecf62;
    border: 1px solid #eecf62;
    border-radius: 4px;
    white-space: pre-wrap;
    z-index: 9999999;
}
}
/*--------------------------------MEDIA QUERY---------------------------------------*/

@media only screen and (max-width: 767px) {
    
    .modal-web {
        display: none !important;
    }
    .modal-wap {
        /* display: block !important;
        position: fixed;
        z-index: 999999999;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.7);
        padding:0px;
        height: 100%; */
        display: block!important;
        position: fixed;
        z-index: 9999999;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,.7);
        padding: 0;
        height: 100%;
        padding-left: 16px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .img-center {
        width: 100%;
        height: 256px;
    }
    
    .p-modal-content{
        /* width: 270px !important;*/
        /* height: 338px !important; */
        /*padding: 24px 33px 15px 33px !important; */
        width: 89% !important;
        /* padding: 24px 17px 21px!important; */
        padding: 25px 17px 25px 17px;
    }

    .popup-container{
        /* height: 110px; */
        width: 100%;
        overflow: hidden;
    margin-bottom: 20px;
    }

    .popup-input{
        float: left;
        /* border: solid;
        border-color: #DCDCDC; */
        /* padding: 10px 10px 10px 10px; */
        /* height: 86px; */
        /* width: 110px; 
        height: 230px;
        width: 265px;
        margin-right: 12px;
        margin-bottom: 12px;*/
    }

    .modal-content {
        /* padding: 22px 22px 24px 22px;
        top:20%;
        background-color: #ffffff;
        margin: auto;
        border: 1px solid #ffffff;
        width: calc(100% - 92px);
        position: relative;
        border-radius: 4px;
        overflow: auto;
        border-radius: 8px; */
        /* padding: 22px 22px 24px; */
        top: 13%;
        background-color: #fff;
        margin: auto;
        border: 1px solid #fff;
        /* width: calc(100% - 92px); */
        position: relative;
        border-radius: 4px;
        overflow: auto;
        border-radius: 8px;
    }
    
    .modal-content::-webkit-scrollbar 
    { 
         width: 8px !important;height: 8px !important; 
         background-color: #EFEFF4;
    }
    .modal-content::-webkit-scrollbar-thumb 
    {
        -webkit-border-radius: 100px;
        border-radius: 100px;
        background: #B4B4BC;
        -webkit-box-shadow: inset 0 0 0px  #B4B4BC;
       
    }
    
    .modal-button{
        margin: 0px auto;
        text-align: center;
        /* margin-top: 30px !important; */
    }
    
    .p-modal-button{
        width : 136px !important;
        /* position: absolute;
        bottom: 32px;
        right: 103px; */
    }
    /* display: none; */
    .close-img{float: right; width: 12px; height: 12px; }
    .c-text{font-family: BlenderPro-Bold; font-size: 22px; text-align: center;
        color: #000000; margin-top: 12px;
        width: 174px;
        margin: 0px auto;
        line-height: 21px;}
    .p-desc{font-family: BlenderPro-Book; font-size: 17px; text-align: center;
        color: #000000; margin-top: 24px;}
    .m-btn{    width: 118px;
        height: 36px;
        cursor: pointer;
        background-color: #eecf62;
        border: 1px solid #eecf62;
        border-radius: 4px;
        white-space: pre-wrap;
        color:#000000;
        font-size: 19px;
        font-family: BlenderPro-Bold;
        padding: 12px 0px 11px 0px;
    }

}

/*--------------------------------MEDIA QUERY FOR SMALL SCREEN ---------------------------------------*/
@media only screen and (min-width: 320px) and (max-width: 360px) {

    .modal-web {
        display: none !important;
    }

    .modal-wap {
        /* display: block !important;
        position: fixed;
        z-index: 9999999;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.7);
        padding:0px;
        height: 100%; */
        display: block!important;
        position: fixed;
        z-index: 9999999;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,.7);
        padding: 0;
        height: 100%;
        padding-left: 16px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .img-center {
        width: 100%;
        height: 256px;
    }
    
    .p-modal-content{
        /* width: 215px !important; */
        /* height: 338px !important; */
        /* padding: 24px 33px 15px 33px !important; */
        width: 89% !important;
        /* padding: 24px 17px 21px!important; */
        padding: 25px 17px 25px 17px; 
    }

    .popup-container{
        /* height: 110px; */
        width: 100%;
        overflow: hidden;
    margin-bottom: 20px;
    }

    .popup-input{
        /*float: left;
         border: solid;
        border-color: #DCDCDC;
        padding: 10px 10px 10px 10px; 
        height: 86px;
        width: 88px;*/
        /* height: 230px;
        width: 210px;
        margin-right: 12px;
        margin-bottom: 12px; */
    }

    .modal-content {
        /* padding: 22px 22px 24px 22px;
        top:20%;
        background-color: #ffffff;
        margin: auto;
        border: 1px solid #ffffff;
        width: calc(100% - 92px);
        position: relative;
        border-radius: 4px;
        overflow: auto;
        border-radius: 8px; */

        /* padding: 22px 22px 24px; */
        top: 13%;
        background-color: #fff;
        margin: auto;
        border: 1px solid #fff;
        /* width: calc(100% - 92px); */
        position: relative;
        border-radius: 4px;
        overflow: auto;
        border-radius: 8px;
    }
    
    .modal-content::-webkit-scrollbar 
    { 
         width: 8px !important;height: 8px !important; 
         background-color: #EFEFF4;
    }
    .modal-content::-webkit-scrollbar-thumb 
    {
        -webkit-border-radius: 100px;
        border-radius: 100px;
        background: #B4B4BC;
        -webkit-box-shadow: inset 0 0 0px  #B4B4BC;
       
    }
    
    .modal-button{
        margin: 0px auto;
        text-align: center;
        /* margin-top: 30px !important; */
    }
    
    .p-modal-button{
        width : 136px !important;
        /* position: absolute;
        bottom: 32px;
        right: 103px; */
    }
    /* display: none; */
    .close-img{float: right; width: 12px; height: 12px; }
    .c-text{font-family: BlenderPro-Bold; font-size: 22px; text-align: center;
        color: #000000; margin-top: 12px;
        width: 174px;
        margin: 0px auto;
        line-height: 21px;}
    .p-desc{font-family: BlenderPro-Book; font-size: 17px; text-align: center;
        color: #000000; margin-top: 24px;}
    .m-btn{    width: 118px;
        height: 36px;
        cursor: pointer;
        background-color: #eecf62;
        border: 1px solid #eecf62;
        border-radius: 4px;
        white-space: pre-wrap;
        color:#000000;
        font-size: 19px;
        font-family: BlenderPro-Bold;
        padding: 12px 0px 11px 0px;
    }

}